@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    height: 100vh;
}


/* InfoWindowStyles.css */
.infowindow {
    max-width: 300px;
    font-family: Arial, sans-serif;
    color: #333;
}

.infowindow h4 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}

.infowindow p {
    margin: 5px 0;
    font-size: 14px;
}

.infowindow img {
    width: 100%;
    height: auto;
    margin: 10px 0;
    border-radius: 5px;
}

.infowindow a {
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
}

.infowindow a:hover {
    text-decoration: underline;
}

/* In your CSS file */
.cell-green {
    background-color: #d4edda;
    /* Light green background */
    color: #155724;
    /* Dark green text */
    font-weight: bold;
}

.cell-red {
    background-color: #f8d7da;
    /* Light red background */
    color: #721c24;
    /* Dark red text */
    font-weight: bold;
}

.cell-yellow {
    background-color: #fff3cd;
    /* Light yellow background */
    color: #856404;
    /* Dark yellow/brown text */
    font-weight: bold;
}

.ag-theme-alpine .ag-details-row {
    height: 200px;
    /* Set a fixed height */
    overflow: auto;
    /* Allow scrolling if necessary */
}